import type React from "react";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface TermsDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

export const TermsDialog: React.FC<TermsDialogProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Terms and Conditions</DialogTitle>
				</DialogHeader>
				<div className="prose prose-gray max-h-96 max-w-none overflow-y-auto">
					<p className="text-muted-foreground">
						The Tronspace.vip platform ("Tronspace.vip," "we," "us," or "our")
						provides online services for buying, renting, and selling Tron energy and
						bandwidth. By using our website and services, you agree to the terms and
						conditions outlined below ("Terms").
					</p>
					<h2 className="mt-8 font-bold text-2xl">GENERAL CONDITIONS</h2>
					<div className="mt-6 space-y-6">
						<div className="mt-6 space-y-6">
							<div>
								<h3 className="font-semibold text-xl">
									Age and Eligibility to Use Services:
								</h3>
								<p className="mt-2 text-muted-foreground">
									Our services are intended only for individuals who are 18 years of age
									or older. By using these services, you confirm that you are of legal
									age and meet all necessary eligibility requirements.
								</p>
							</div>

							<div>
								<h3 className="font-semibold text-xl">Service Fees:</h3>
								<p className="mt-2 text-muted-foreground">
									Tronspace.vip may charge fees for certain transactions and services.
									These fees will be clearly disclosed at the time of the transaction or
									service. You are responsible for paying any fees associated with using
									our services.
								</p>
							</div>

							<div>
								<h3 className="font-semibold text-xl">Account Creation and Login:</h3>
								<p className="mt-2 text-muted-foreground">
									To access our services, you need to log in through your wallet. You are
									solely responsible for the security of your account and the protection
									of your private key.
								</p>
							</div>

							<div>
								<h3 className="font-semibold text-xl">Use of Services:</h3>
								<p className="mt-2 text-muted-foreground">
									You agree to use our services solely for lawful purposes. You will not
									use our services for any illegal or unauthorized activities, nor for
									anything that could damage, overload, or disrupt our servers and
									networks. Any attempt to use automated means to access our systems or
									to interfere with their functionality is prohibited.
								</p>
							</div>

							<div>
								<h3 className="font-semibold text-xl">Intellectual Property:</h3>
								<p className="mt-2 text-muted-foreground">
									The content on our website and services, including but not limited to
									text, graphics, logos, images, and software, is the property of
									Tronspace.vip or its licensed third parties and is protected by
									copyright and trademark laws. Any use of this content without our
									written consent is prohibited.
								</p>
							</div>

							<div>
								<h3 className="font-semibold text-xl">Service Interruptions:</h3>
								<p className="mt-2 text-muted-foreground">
									Tronspace.vip strives to ensure continuous availability and
									functionality of our website and services. However, given the nature of
									the internet and technology, we cannot guarantee uninterrupted
									availability. We bear no liability for any losses or damages resulting
									from service interruptions or outages.
								</p>
							</div>

							<div>
								<h3 className="font-semibold text-xl">Security:</h3>
								<p className="mt-2 text-muted-foreground">
									You are solely responsible for the security of your wallet and for all
									activities that occur under your account. We strongly recommend
									safeguarding your wallet information and never sharing your private key
									with others. Tronspace.vip is not liable for any losses or damages
									resulting from unauthorized access to your wallet.
								</p>
							</div>
						</div>
					</div>
				</div>
				<DialogFooter>
					<Button onClick={onClose}>OK</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
